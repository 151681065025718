import { call, put, takeEvery } from "redux-saga/effects";
import { MenuActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import {getAllMenuCategoriesSuccess, storeMenuCategorySuccess, getRestaurantMenuCategoriesSuccess, updateMenuCategoryByIdSuccess, getMenuCategoryByIdSuccess, apiCallError, deleteMenuCategorySuccess} from "./actions";

import { addMenuCategoryAsync, deleteMenuCategoryByIdAsync, getAllMenuCategoriesAsync, getMenuCategoryByIdAsnyc, getRestaurantMenuCategoriesAsync, getRestaurantMenuCategoriesWithItemsAsync, updateMenuCategoryByIdAsync,uploadSingleImageAsync } from "../../helpers/backend_helper";

function* getAllMenuCategories() {
  try {
    const response: IResponse = yield call(getAllMenuCategoriesAsync);
    console.log('response =>', response)
    if(response.success) {
      yield put(getAllMenuCategoriesSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* getRestaurantMenuCategories({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getRestaurantMenuCategoriesAsync, id);
    if(response.success) {
      yield put(getRestaurantMenuCategoriesSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* getRestaurantMenuCategoriesWithItems({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getRestaurantMenuCategoriesWithItemsAsync, id);
    if(response.success) {
      yield put(getRestaurantMenuCategoriesSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* updateMenuCategory({ payload: {id, category, history} }: any) {
  try {
    if (!!category.BackImage) {
      if (typeof(category.BackImage) === 'string' && category.BackImage.startsWith("id:")) {
        category.BackImage = parseInt(category.BackImage.replace("id:", ""))
      } else {
        if (typeof(category.BackImage)!=='number') {
          const formData = new FormData();
          formData.append("images", category.BackImage);
          const imgRes: IResponse = yield call(uploadSingleImageAsync, formData, category.RestaurantId);
          category.BackImage = imgRes.result[0]?.id;
        }
      }
    }
    const response: IResponse = yield call(updateMenuCategoryByIdAsync, id, category);
    if(response.success) {
      yield put(updateMenuCategoryByIdSuccess(response.result));
      if (history) {
        history.push('/menu/categories')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* addMenuCategory({ payload: menuCategory }: any) {
  try {
    const response: IResponse = yield call(addMenuCategoryAsync, menuCategory);
    if(response.success) {
      yield put(storeMenuCategorySuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* getMenuCategoryById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMenuCategoryByIdAsnyc, id);
    if(response.success) {
      yield put(getMenuCategoryByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* deleteMenuCategory({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteMenuCategoryByIdAsync, id);
    if(response.success) {
      yield put(deleteMenuCategorySuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(apiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(MenuActionTypes.GET_ALL_MENU_CATEGORIES, getAllMenuCategories)
  yield takeEvery(MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES, getRestaurantMenuCategories)
  yield takeEvery(MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES_WITH_ITEMS, getRestaurantMenuCategoriesWithItems)
  yield takeEvery(MenuActionTypes.UPDATE_MENU_CATEGORY_BY_ID, updateMenuCategory);
  yield takeEvery(MenuActionTypes.DELETE_MENU_CATEGORY_BY_ID, deleteMenuCategory);
  yield takeEvery(MenuActionTypes.ADD_MENU_CATEGORY, addMenuCategory)
  yield takeEvery(MenuActionTypes.GET_MENU_CATEGORY_BY_ID, getMenuCategoryById);
}

export default restaurantSaga;


