import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import paginationFactory, {PaginationListStandalone,PaginationProvider,} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteAdminByDocId} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import AddRestaurant from "./add-restaurant";
import { getAllRestaurants } from "../../store/actions";
import SendVM from "./send-vm";

export const getMenuImageUrl = (backImage: any) => !!backImage && typeof(backImage) === 'string' && backImage.startsWith("id:") ? process.env.REACT_APP_API_URL + "/menu/images/" + backImage.replace("id:", "") : 
  typeof(backImage) === 'number' ? process.env.REACT_APP_API_URL + "/menu/images/" + backImage : backImage


const Restaurant = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { restaurants, loading } = useSelector( (state: any) => state.restaurant);
  const { currentUser } = useSelector((state: any) => state.login);
  const [restaurantsList, setRestaurantsList] = useState<any>([]);
  const [currentAdmin, setCurrentAdmin] = useState<any>([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [selectedTelNo, setSelTelNo] = useState<string>('');
  const [showSendVM, setShowSendVM] = useState<Boolean>(false);

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPage: 20,
    totalSize: restaurants.length, // replace later with size(companies),
    custom: true,
  };

  const resTaurantsListColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "ShopName",
      text: "ShopName",
      sort: true,
    },
    {
      dataField: "BuildingName",
      text: "BuildingName",
      sort: true,
    },
    {
      dataField: "Street",
      text: "Street",
      sort: true,
    },
    {
      dataField: "City",
      text: "City",
      sort: true,
    },
    {
      dataField: "Country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "PostCode",
      text: "Postcode",
      sort: true,
    },
    {
      dataField: "Tel1",
      text: "Tel1",
      sort: true,
      formatter: (cell: any, row: any) => (
        <a href="#" onClick={() => sendVM(cell)}>
          <span>{cell} {parseInt(cell)>0}</span>
        </a>
      )
    },
    {
      dataField: "Tel2",
      text: "Tel2",
      sort: true,
      formatter: (cell: any, row: any) => (
        <a href="#" onClick={() => sendVM(cell)}>
          <span>{cell} {parseInt(cell)>0}</span>
        </a>
      )
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "FreeAgentEmail",
      text: "FreeAgent Email",
      sort: true,
    },
    {
      dataField: "WebURL",
      text: "WebURL",
      sort: true,
    },
    {
      dataField: "menu",
      editable: false,
      text: "Action",
      formatter: (cellContent: any, restaurant: any) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleEditRestaurant(restaurant)}
            ></i>
          </Link>

          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteRestaurant(restaurant)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllRestaurants());
  }, []);

  useEffect(() => {
    setRestaurantsList(restaurants);
  }, [restaurants]);

  const handleEditRestaurant = (restaurant: any) => {
    history.push(`/restaurants/${restaurant.id}`);
  };

  const handleDeleteRestaurant = (admin: any) => {
    setCurrentAdmin(admin);
    setconfirm_alert(true);
  };

  const confirmDelete = () => {
    setconfirm_alert(false);
    dispatch(deleteAdminByDocId(currentAdmin.id));
  };

  const handleAddNewRestaurant = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };
  
  const sendVM = (telNo: string) => {
    if (!!telNo) {
      setSelTelNo(telNo);
      setShowSendVM(true);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={restaurantsList}
                          columns={resTaurantsListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        Restaurants List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({restaurants.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleAddNewRestaurant}
                                          >
                                            <i className="bx bx-plus me-1"></i>{" "}
                                            Add New
                                          </Link>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                confirmDelete();
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          <AddRestaurant open={drawer} onDrawerClose={onDrawerClose} />
          
          <SendVM open={showSendVM} onDrawerClose={() => setShowSendVM(false)} telNo={selectedTelNo} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Restaurant);
