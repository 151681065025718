import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { del, get } from "src/helpers/api_helper";
import { Gallery } from "react-grid-gallery";
import { getMenuImageUrl } from ".";

const ImageGallery = (props: any) => {
  const [images, setImages] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);

  useEffect(() => {
    if (props.open && props.restaurantId) {
        get("/menu/images/restaurant-id/" + props.restaurantId).then((imagesData) => {
            setImages(imagesData.result);
            setSelected([]);
        })
    }
  }, [props.open, props.restaurantId])
  
  const imageData = images.map((image: any, idx: number) => ({
        src: getMenuImageUrl(image.id),
        width: 100,
        height: 100,
        isSelected: selected.indexOf(idx) >= 0,
  }))

  const handleRemove = () => {
    del("/menu/images/batch?idArray=" + selected.map((sIdx: number) => images[sIdx].id)).then((res: any) => {
      setImages(images.filter((img: any, idx: number) => selected.indexOf(idx) < 0));
      setTimeout(() => {
        setSelected([]);
      }, 100);
    }).catch((err: any) => {
      window.alert("Can't not remove images because they are using for menu categories or menu items");
    })
  }

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Select image from gallery</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="p-4" style={{width: 500}}>
            <Gallery 
                images={imageData} 
                onClick={(idx) => !props.editable ? props.onSelect(images[idx].id) : setSelected(selected.indexOf(idx) >= 0 ? selected.filter((ss: any) => ss != idx) : [...selected, idx])} 
                margin={10} 
                defaultContainerWidth={300} 
            />
            {selected.length > 0 && (
                <button className="btn btn-light" onClick={handleRemove}> 
                    Delete images
                </button>
            )}
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(ImageGallery);
