import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getAdminById, updateAdminByDocId, getAllRestaurants } from "../../store/actions";
import { Col, Container, Label, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import Select from 'react-select';
let options:any = [];

const EditAdmin = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);

  const { singleAdmin, loading } = useSelector((state: any) => state.admin);
  const { restaurants, singleRestaurant } = useSelector( (state: any) => state.restaurant);
  useEffect(() => {
    dispatch(getAdminById(id));
    dispatch(getAllRestaurants())
  }, []);

  useEffect(()=>{
    options = [];
    let selRestIds:any;
    if (singleAdmin?.RestaurantIds) {
      selRestIds = JSON.parse(singleAdmin?.RestaurantIds)
    }
    let selectedRest:any = [];
    restaurants.map((restaurant:any)=>{
      const option = {value:restaurant.id,label:restaurant.ShopName};
      if (selRestIds?.some((id:any)=>id===restaurant.id)) {
        selectedRest.push(option)
      }
      options.push(option)
    })
    setSelectedRestaurants(selectedRest);
  },[restaurants])

  const handleEditAdmin = (values: any) => {
    const RestaurantIds = selectedRestaurants.map((item:any)=>item.value);
    values.RestaurantIds = JSON.stringify(RestaurantIds);
    values.firstname=values.name;
    dispatch(updateAdminByDocId(id, values, history));
  };

  const handleChangeRestaurant = (selectedRestaurant:any)=>{
    setSelectedRestaurants(selectedRestaurant)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditAdmin(values);
            }}
          >
            <Row>
             
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={
                      {
                        required: { value: true },
                      }
                    }
                    value={singleAdmin?.firstname || ''}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label>Restaurant</Label>
                  <Select
                    isMulti
                    options={options}
                    value={selectedRestaurants}
                    onChange={handleChangeRestaurant}
                  ></Select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="UserName"
                    type="text"
                    errorMessage="Invalid UserName"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.username || ''}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="text"
                    errorMessage="Invalid email"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.email || ''}

                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user update-btn"
                    disabled={loading}
                  >
                    {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Update</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditAdmin);
